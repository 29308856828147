.color__items {
  height: 100px;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  z-index: 999;
}
.color__item {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: block;
  margin-top: 20px;
  cursor: pointer;
  transition: all 0.4s;
}
.color__item:hover,
.color__item.active {
  transform: scale(1.2);
}
/* .test {
  cursor: url('./iconSvg.svg'), auto;
} */
.test2 {
  border: 1px dashed transparent;
}

.button {
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin: 0 auto;
  width: 70px;
  height: 70px;
  font-size: 52px;
  -webkit-transition: all 0.4s ease-in;
  transition: all 0.4s ease-in;
}
.button:before {
  content: '';
  background-color: aliceblue;
  border-radius: 50%;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-transform: scale(0.001, 0.001);
  transform: scale(0.001, 0.001);
}
.button:focus {
  outline: 0;
  color: #fff;
}
.button:focus:before {
  -webkit-animation: effect_dylan 0.8s ease-out;
  animation: effect_dylan 0.8s ease-out;
}
@-webkit-keyframes effect_dylan {
  50% {
    -webkit-transform: scale(1.5, 1.5);
    transform: scale(1.5, 1.5);
    opacity: 0;
  }
  99% {
    -webkit-transform: scale(0.001, 0.001);
    transform: scale(0.001, 0.001);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(0.001, 0.001);
    transform: scale(0.001, 0.001);
    opacity: 1;
  }
}
@keyframes effect_dylan {
  50% {
    -webkit-transform: scale(1.5, 1.5);
    transform: scale(1.5, 1.5);
    opacity: 0;
  }
  99% {
    -webkit-transform: scale(0.001, 0.001);
    transform: scale(0.001, 0.001);
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(0.001, 0.001);
    transform: scale(0.001, 0.001);
    opacity: 1;
  }
}
